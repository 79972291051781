/* global JsonModel */
import i18n from './i18n.js';
import i18next from 'i18next';
import { Issue } from './model/issue.js';

import autosize from 'autosize';
import tinymce from 'tinymce/tinymce';

let _ = i18n('issueEditor');

/*** This object manage the popup view to edit/create an issue. ***/
export class IssueView {
    constructor(parent, sidePanelDiv, tinymceSkinUrl) {
        this.parent = parent;
        this.domParent = sidePanelDiv;
        this.issue = new Issue({});
        this.tinymceSkinUrl = tinymceSkinUrl;
    }

    /*** Call when 'Ok' button is press. Ask to controller to create the issue with ajax call. ***/
    async createIssue() {
        try {
            let result = await this.parent.ajaxCreateIssue(this.issue);
            this.issue = result["data"][0];
            if (this.issue.relatedElement !== ""){
                this.parent.callback('addRelatedElement', this.issue.id, this.issue.relatedElement);
            }
            await this.parent.updateIssuesView();
            $("#issuePopup").modal('hide');
        } catch (error) {
            this.errorPopup(_("An error occurred while trying to create the issue. Please try again later."), error);
        }
    }

    /**
     * Call when a field as been change when editing issue. Ask to controller to update the issue with ajax call.
     *
     * @return true if update was successful, false otherwise
     */
    async updateIssue(updateComments = true) {
        try {
            let callResponse = await this.parent.ajaxUpdateIssue(this.issue);
            await this.parent.updateIssuesView();
            if (updateComments) {
                this.issue.events = callResponse["data"][0].events;
                this.updateCommentsList();
            }
            return true;
        } catch (error){
            this.errorPopup(_("An error occurred while trying to update the issue. Please try again later."), error);
            return false;
        }
    }

    /*** Call when 'Resolve' button is press. Ask to controller to update the issue with ajax call. ***/
    async resolveIssue() {
        this.issue.isOpen = false;
        let result = await this.updateIssue(false);
        if (this.issue.relatedElement !== ""){
            this.parent.callback('removeRelatedElement', this.issue.id, this.issue.relatedElement);
        }
        if (result) {
            this.$issueModal.modal('hide');
        }
    }

    /*** Call when 'Reopen' button is press. Ask to controller to update the issue with ajax call. ***/
    async reopenIssue() {
        this.issue.isOpen = true;
        let result = await this.updateIssue(false);
        if (this.issue.relatedElement !== ""){
            this.parent.callback('addRelatedElement', this.issue.id, this.issue.relatedElement);
        }
        if (result) {
            this.$issueModal.modal('hide');
        }
    }

    /*** Call when 'Delete' button is press. Ask to controller to delete the issue with ajax call. ***/
    async deleteIssue() {
        try {
            await this.parent.ajaxDeleteIssue(this.issue.id);
            if (this.issue.relatedElement !== ""){
                this.parent.callback('removeRelatedElement', this.issue.id, this.issue.relatedElement);
            }
            await this.parent.updateIssuesView();
            this.$issueModal.modal('hide');
        } catch(error) {
            this.errorPopup(_("An error occurred while trying to delete the issue. Please try again later."), error);
        }
    }

    /*** Format a comment to accept in the URL parameters. Also add type tag so it dosen't confuse IssueComment and IssueSystemComment in backend. ***/
    commentToEvent(comment) {
        let event = {};
        event.guid = comment.guid;
        event.replyTo = comment.replyTo;
        event.message = comment.message;
        return event;
    }

    /*** Call when comment is created in front-end. Ask to controller to save it in back-end. ***/
    async createComment(comment) {
        try {
            await this.parent.ajaxCreateComment(this.issue.id, this.commentToEvent(comment));
            await this.parent.updateIssuesView();
        } catch (error) {
            this.errorPopup(_("An error occurred while trying to create a comment. Please try again later."), error);
        }
    }

    /*** Call when comment is updated in front-end. Ask to controller to save it in back-end. ***/
    async updateComment(comment) {
        try {
            await this.parent.ajaxUpdateComment(this.issue.id, this.commentToEvent(comment));
            await this.parent.updateIssuesView();
        } catch (error) {
            this.errorPopup(_("An error occurred while trying to update a comment. Please try again later."), error);
        }
    }

    /*** Call when comment is deleted in front-end. Ask to controller to delete it in back-end. ***/
    async deleteComment(commentId) {
        try {
            await this.parent.ajaxDeleteComment(this.issue.id, commentId);
            await this.parent.updateIssuesView();
        } catch (error) {
            this.errorPopup(_("An error occurred while trying to delete a comment. Please try again later."), error);
        }
    }

    /*** Call by Controller when issuesView asking to display issue. Ask to controller to get the issue with ajax call. ***/
    async popupFromExistingIssue(issueId) {
        try {
            let callResponse = await this.parent.ajaxGetIssueById(issueId);
            this.createPopupView(callResponse['data'][0]);
        } catch (error) {
            this.errorPopup(_("An error has occurred while trying to load the issues. Please try again later."), error);
        }
    }

    /*** Call when the issue 'title' field is updated. Ask to controller to update it in back-end. ***/
    async setIssueTitleFromDOM(titleNode) {
        if (titleNode.value.trim() !== "") {
            this.issue.title = titleNode.value.trim();
            if (this.issue.id !== -1 && this.issue.isOpen) {
                this.updateIssue();
            }
        }
    }

    /*** Call when the issue 'description' field is updated. Ask to controller to update it in back-end. ***/
    async setIssueDescriptionFromDOM(newValue) {
        if (this.issue.description !== newValue) {
            this.issue.description = newValue;
            if (this.issue.id !== -1 && this.issue.isOpen) {
                this.updateIssue();
            }
        }
    }

    /*** Call when the issue 'related element' field is updated. Ask to controller to update it in back-end. ***/
    async setRelatedElementFromTypeahead(id) {
        if (this.issue.relatedElement !== id){
            if (!id) {
                if (document.getElementById("issueRelatedElementBar").value === "") {
                    if (this.issue.id !== -1){
                        this.parent.callback('removeRelatedElement', this.issue.id, this.issue.relatedElement);
                    }
                    this.issue.relatedElement = "";
                    this.issue.relatedElementName = "";
                }
            } else {
                if (this.issue.relatedElement !== "" && this.issue.id !== -1){
                    this.parent.callback('removeRelatedElement', this.issue.id, this.issue.relatedElement);
                }
                this.issue.relatedElement = id;
                this.issue.relatedElementName = this.itemGetName(this.parent.getElementById(id));
                if (this.issue.id !== -1){
                    this.parent.callback('addRelatedElement', this.issue.id, this.issue.relatedElement);
                }
            }
            if (this.issue.id !== -1 && this.issue.isOpen) {
                this.updateIssue();
            }
        }
    }

    /*** Call when the issue 'assign to' field is updated. Ask to controller to update it in back-end. ***/
    async setAssingToFromTypeahead(id) {
        if (!id) {
            if (document.getElementById("issueassignedToBar").value === "") {
                this.issue.assignedTo = "";
            }
        } else {
            this.issue.assignedTo = id;
        }
        if (this.issue.id !== -1 && this.issue.isOpen) {
            this.updateIssue();
        }
    }

    /*** Delete and recreate the comments of the issue. ***/
    updateCommentsList() {
        let node = document.getElementById("issueComments");
        if (node){
            let parent = node.parentNode;
            parent.removeChild(document.getElementById("issueComments"));
            let issueComments = document.createElement("div");
            issueComments.id = "issueComments";
            parent.appendChild(issueComments);
            this.initCommenting(issueComments);
        }
    }

    /*** Create and display the issue popup. ***/
    async createPopupView(issue) {
        var that = this;
        this.issue = new Issue(issue);
        let id = this.issue.id;

        //Create modal base
        let issueModal = document.createElement("div");
        issueModal.className = "modal";
        issueModal.id = "issuePopup";
        issueModal.tabindex = "-1";
        issueModal.role = "dialog";
        issueModal.setAttribute("aria-labelledby", "issuePopup");
        issueModal.setAttribute("aria-hidden", "true");

        $(issueModal).on('hidden.bs.modal', (e) => {
            var editor = tinymce.get("issueDescriptionTinyMCE_" + id);
            if (editor) {
                editor.destroy();
            }
            e.currentTarget.remove();
        });

        let issueModalDialog = document.createElement("div");
        issueModalDialog.className = "modal-dialog";
        issueModalDialog.role = "document";
        let issueModalContent = document.createElement("div");
        issueModalContent.className = "modal-content";
        issueModalDialog.appendChild(issueModalContent);
        issueModal.appendChild(issueModalDialog);

        issueModalContent.appendChild(this.createPopupViewHeader());
        //Modal-Body
        let issueModalBody = document.createElement("div");
        issueModalBody.id = "issueModalBody";
        issueModalBody.className = "modal-body";
        issueModalContent.appendChild(issueModalBody);
        issueModalContent.appendChild(this.createPopupViewFooter());
        document.body.appendChild(issueModal);

        this.$issueModal = $(issueModal);
        this.$issueModal.on("shown.bs.modal", function () {
            var m = that.$issueModal.data('bs.modal');
            if (m.$backdrop) {
                that.$issueModal.before(m.$backdrop);
                m.$backdrop.css("z-index", "auto");
            }
        });
        this.$issueModal.modal();

        await this.createPopupViewBody(issueModalBody);

        // To force the resize title Bar
        autosize.update(document.getElementById('issueTitleBar'));

        // Focus on title bar if it is a new issue
        if (this.issue.id === -1) {
            document.getElementById("issueTitleBar").focus();
        }

        //Description textarea configuration:
        if (this.issue.isOpen) {
            var view = this;
            tinymce.init({
                selector: '#issueDescriptionTinyMCE_' + this.issue.id,
                inline: true,
                language: i18next.language && i18next.language.indexOf("fr") === 0 ? "fr_FR" : undefined,
                skin_url: this.tinymceSkinUrl,
                statusbar: false,
                menubar: false,
                contextmenu: false,
                toolbar: [
                    "undo redo | cut copy paste | alignleft aligncenter alignright alignjustify | outdent indent | styleselect | link",
                    "bold italic underline | forecolor backcolor | bullist numlist | fontselect fontsizeselect"
                ],
                toolbar_mode: "wrap",
                plugins:  "paste, lists, advlist, autolink, link",
                paste_data_images: true,
                browser_spellcheck: true,
                default_link_target: "_blank",
                setup: function (editor) {
                    editor.on('blur', function (ed) {
                        view.setIssueDescriptionFromDOM(ed.target.getContent());
                    });
                },
                style_formats: [
                    { title: "Paragraph", format: "p" },
                    { title: "Address 1", format: "address" },
                    { title: "Preformatted", format: "pre" },
                    { title: "Header 1", format: "h1" },
                    { title: "Header 2", format: "h2" },
                    { title: "Header 3", format: "h3" },
                    { title: "Header 4", format: "h4" },
                    { title: "Header 5", format: "h5" },
                    { title: "Header 6", format: "h6" }
                ],
                font_formats: 'Andale Mono=andale mono,monospace;' +
                    'Arial=arial,helvetica,sans-serif;' +
                    'Arial Black=arial black,sans-serif;' +
                    'Book Antiqua=book antiqua,palatino,serif;' +
                    'Comic Sans MS=comic sans ms,sans-serif;' +
                    'Courier New=courier new,courier,monospace;' +
                    'Georgia=georgia,palatino,serif;' +
                    'Helvetica=helvetica,arial,sans-serif;' +
                    'Impact=impact,sans-serif;' +
                    'Open Sans=open sans,arial,sans-serif;' +
                    'Symbol=symbol;' +
                    'Tahoma=tahoma,arial,helvetica,sans-serif;' +
                    'Terminal=terminal,monaco,monospace;' +
                    'Times New Roman=times new roman,times,serif;' +
                    'Trebuchet MS=trebuchet ms,geneva,sans-serif;' +
                    'Verdana=verdana,geneva,sans-serif;' +
                    'Webdings=webdings;' +
                    'Wingdings=wingdings,zapf dingbats'
            });
        }
    }

    validateTitle(){
        if (this.issue.id === -1){
            document.querySelector("#issuePopup .modal-footer .btn.btn-primary").disabled = (document.querySelector("#issuePopup .modal-header #issueTitleBar").value.trim() === "");
        }
    }

    /*** Create and display the issue popup header. Just creating HTML in Js and add events listeners on them. ***/
    createPopupViewHeader() {
        //Modal-Header
        let issueModalHeader = document.createElement("div");
        issueModalHeader.id = "issueModalHeader";
        issueModalHeader.className = "modal-header";
        //Add issueIcon to header
        let issueHeaderIcon = document.createElement("div");
        issueHeaderIcon.id = "issueIcon";
        //Create Title
        let issueTitleBar = document.createElement("textarea");
        issueTitleBar.className = "text-primary form-control";
        issueTitleBar.id = "issueTitleBar";
        issueTitleBar.required = true;
        issueTitleBar.value = this.issue.title;
        issueTitleBar.disabled = (!this.issue.isOpen);
        issueTitleBar.placeholder = _("Enter issue title here");
        autosize(issueTitleBar);
        issueTitleBar.addEventListener('change', this.setIssueTitleFromDOM.bind(this, issueTitleBar));
        issueTitleBar.addEventListener('keyup', this.validateTitle.bind(this));

        issueModalHeader.appendChild(issueHeaderIcon);
        issueModalHeader.appendChild(issueTitleBar);
        if (this.issue.id !== -1) {
            let issueNumber = document.createElement("div");
            issueNumber.id = "issueNumber";
            issueNumber.innerText = "#" + this.issue.id;
            issueModalHeader.appendChild(issueNumber);
        }
        //Append and create X button
        issueModalHeader.insertAdjacentHTML('beforeend', '<button class="close" data-dismiss="modal" aria-label="Close" id="issueCloseButton"><span aria-hidden="true">×</span></button>');
        return issueModalHeader;
    }

    /*** Create and display the issue popup body. Just creating HTML in Js and add events listeners on them. ***/
    async createPopupViewBody(issueModalBody) {
        //Create div that is write Descritpion
        let issueDescriptionGroup = document.createElement("div");
        issueDescriptionGroup.className = "form-group";
        let issueDescriptionLabel = document.createElement("label");
        issueDescriptionLabel.innerText = _("Description") + ":";
        issueDescriptionGroup.appendChild(issueDescriptionLabel);
        //Create textarea that contain description
        let issueDescriptionTinyMCE = document.createElement("div");
        issueDescriptionTinyMCE.id = "issueDescriptionTinyMCE_" + this.issue.id;
        issueDescriptionTinyMCE.innerHTML = this.issue.description;
        issueDescriptionTinyMCE.setAttribute("class", "richtext form-control");
        issueDescriptionGroup.appendChild(issueDescriptionTinyMCE);
        issueModalBody.appendChild(issueDescriptionGroup);

        //Create div that is write related element
        let issueRelatedElementGroup = document.createElement("div");
        issueRelatedElementGroup.className = "form-group";
        let issueRelatedElement = document.createElement("label");
        issueRelatedElement.innerText = _("Related element") + ":";
        issueRelatedElementGroup.appendChild(issueRelatedElement);
        let issueRelatedElementBar;
        if (this.parent.isRelatedElementAPI()){
            //Create Typeahead for related element
            issueRelatedElementBar = this.makeRelatedElementTypeahead();
        } else{
            issueRelatedElementBar = document.createElement("div");
            issueRelatedElementBar.innerHTML = this.issue.relatedElementName !== "" ? this.issue.relatedElementName : _("None");
        }
        issueRelatedElementBar.id = "issueRelatedElementBar";
        issueRelatedElementGroup.appendChild(issueRelatedElementBar);
        issueModalBody.appendChild(issueRelatedElementGroup);

        if (this.issue.id !== -1) {
            //Fill relatedElementBar with current the relatedElementBar
            if (this.parent.isRelatedElementAPI()){
                if (this.issue.relatedElement !== "") {
                    let relatedElement = this.parent.getElementById(this.issue.relatedElement);
                    issueRelatedElementBar.value = relatedElement ? this.itemGetName(relatedElement) : "";
                    issueRelatedElementBar.setAttribute("data-id", relatedElement ? relatedElement.getId(): "");
                    this.issue.relatedElementName = this.itemGetName(relatedElement);
                }
            }
            if (!this.parent.isPersonalRepository()){
                //Create div that is write Assign to
                let issueassignedToGroup = document.createElement("div");
                issueassignedToGroup.className = "form-group";
                let issueassignedTo = document.createElement("label");
                issueassignedTo.innerText = _("Assigned to") + ":";
                issueassignedToGroup.appendChild(issueassignedTo);
                //Create assign element Bar
                let issueassignedToBar;
                try {
                    let members = this.memberArrayToJsonArray(await this.parent.ajaxGetMembersFromSharePlace());
                    issueassignedToBar = this.makeTypeaheadField(members, this.issue.assignedTo);
                    issueModalBody.appendChild(issueassignedToBar);
                } catch(err) {
                    issueassignedToBar = document.createElement("div");
                    issueassignedToBar.innerHTML = "<i>" + _("An error occurred while trying to get users informations. please try again later.") + "</i>";
                    issueassignedToBar.disabled = true;
                }
                issueassignedToBar.id = "issueassignedToBar";
                issueassignedToGroup.appendChild(issueassignedToBar);
                issueModalBody.appendChild(issueassignedToGroup);
            }
            if (!this.parent.isInModeler()){
                try {
                    let fileInformation = await this.parent.ajaxGetfileInformations(this.issue.fileId);
                    fileInformation = fileInformation.data[0].file;
                    let issueModelGroup = document.createElement("div");
                    issueModelGroup.id = "issueModelGroup";
                    issueModelGroup.className = "form-group";
                    let issueModel = document.createElement("label");
                    issueModel.innerText = _("From") + ":";
                    issueModelGroup.appendChild(issueModel);

                    let issueModelDiv = document.createElement("div");
                    issueModelDiv.className = "item";
                    issueModelDiv.setAttribute("data-mimetype", fileInformation.mimetype);

                    let issueModelImg = document.createElement("i");
                    issueModelImg.className = "img-file";
                    issueModelDiv.appendChild(issueModelImg);

                    issueModelDiv.addEventListener("click", function() {
                        if (this.parent.mimetypes) {
                            var descriptor = {
                                f: {
                                    mimetype: fileInformation.mimetype,
                                    name: fileInformation.name,
                                    path: fileInformation.path,
                                    sku: fileInformation.sku
                                },
                                r: {
                                    repositoryId: this.parent.repositoryId,
                                    url: '/publicapi'
                                },
                                t: 'publicapi'
                            };

                            this.openExternalModel(fileInformation.mimetype, btoa(unescape(encodeURIComponent(JSON.stringify(descriptor)))));
                        }
                    }.bind(this));

                    let issueModelName = document.createElement("span");
                    issueModelName.className = "name";
                    issueModelName.innerText = fileInformation.name;
                    issueModelDiv.appendChild(issueModelName);
                    issueModelGroup.appendChild(issueModelDiv);
                    issueModalBody.appendChild(issueModelGroup);
                } catch(e) {
                    //the file has probably been deleted.
                }
            }
            let issueComments = document.createElement("div");
            issueComments.id = "issueComments";
            issueModalBody.appendChild(issueComments);
            this.initCommenting(issueComments);
        } else {
            if (this.parent.isRelatedElementAPI()){
                let selectedElement = this.parent.getSelectedElement();
                issueRelatedElementBar.value = selectedElement ? this.itemGetName(selectedElement) : "";
                this.issue.relatedElement = selectedElement ? selectedElement.getId(): "";
                issueRelatedElementBar.setAttribute("data-id", this.issue.relatedElement);
                this.issue.relatedElementName = this.itemGetName(selectedElement);
            }
        }
    }

    openExternalModel(mimetype, descriptor) {
        this.parent.mimetypes.forEach(function (obj) {
            if (obj.name === mimetype) {
                var url = null;
                ["editor", "portal", "viewer"].forEach(function(mode) {
                    if (!url && obj[mode]) {
                        url = obj[mode];
                    }
                }.bind(this));

                var url_params = "#model=" + encodeURIComponent(descriptor);

                if (this.issue.relatedElement) {
                    url_params += "&elementid=" + encodeURIComponent(this.issue.relatedElement);
                }

                if (url) {
                    window.open(url + url_params);
                }
            }
        }.bind(this));
    }

    /*** Create and display the issue comments. Init the comments plugins. ***/
    initCommenting(div) {
        const fnUserImageUrl = () => { return this.parent.getCurrentUserInformations().photoThumbnail; };
        const fnUserDisplayName = () => { return this.parent.getCurrentUserInformations().name; };
        const fnUserEmail = () => { return this.parent.getCurrentUserInformations().email; };
        this.issue.eventsToComments(this.parent.getUsersInformations(), this.parent.elementAPI);
        this.modelComment = new JsonModel(this.issue.events);
        this.modelComment.register('add', (comment) => { this.createComment(comment.toString()); });
        this.modelComment.register('update', (comment) => { this.updateComment(comment.toString()); });
        this.modelComment.register('remove', (guid) => { this.deleteComment(guid); });
        $(div).comments('comments', {
            bpmElement: 'issueView',
            json: this.modelComment,
            fnUserImageUrl: fnUserImageUrl,
            fnUserDisplayName: fnUserDisplayName,
            fnUserEmail: fnUserEmail,
            filterByElement: true,
            allowNoComments: false,
            onlyAuthorCanDelete: true,
            serverUrl: this.parent.endPoint,
            tinymceSkinUrl: this.tinymceSkinUrl
        });
    }

    /*** Create and display the issue popup footer. Just creating HTML in Js and add events listeners on them. ***/
    createPopupViewFooter() {
        //Create Issue Modal Footer
        let issueModalFooter = document.createElement("div");
        issueModalFooter.className = "modal-footer";

        if (this.issue.id === -1) {
            issueModalFooter.appendChild(this.createDomButton(_("Cancel"), false, null, true));
            let okButton = this.createDomButton(_("OK"), true, this.createIssue);
            okButton.disabled = true;
            issueModalFooter.appendChild(okButton);
        } else {
            if (this.issue.isOpen) {
                issueModalFooter.appendChild(this.createDomButton(_("Resolve"), false, this.resolveIssue));
            }else{
                issueModalFooter.appendChild(this.createDomButton(_("Reopen"), false, this.reopenIssue));
            }
            if (this.issue.getIssueCreatorEmail() === this.parent.getCurrentUserInformations().email) {
                issueModalFooter.appendChild(this.createDomButton(_("Delete"), false, this.deleteIssue));
            }
            issueModalFooter.appendChild(this.createDomButton(_("Close"), true, null, true));

        }
        return issueModalFooter;
    }

    /*** Utility function to create a Button. ***/
    createDomButton(text, isPrimaryButton, fn = null, closeBox = false) {
        let domButton = document.createElement("Button");
        domButton.innerText = text;
        domButton.className = isPrimaryButton ? "btn btn-primary" : "btn btn-default";
        if (closeBox) { domButton.setAttribute("data-dismiss", "modal"); }
        if (fn !== null) {
            domButton.addEventListener('click', fn.bind(this));
        }
        return domButton;
    }

    /*** Utility function to translate Element json object to something that typeahead will understand. ***/
    elementArrayToJsonArray(elements) {
        let jsonArray = [];
        elements.forEach(element => {
            jsonArray.push({ "id": element.getId(), "name": this.itemGetName(element) });
        });
        return jsonArray;
    }

    /*** Utility function to translate Member json object to something that typeahead will understand. ***/
    memberArrayToJsonArray(members) {
        let jsonArray = [];
        members["data"].forEach(member => {
            if (member.rights["write"]) {
                jsonArray.push({ "id": member["email"], "name": member["name"] });
            }
        });
        return jsonArray;
    }

    /*** Utility function to to create a typeahead filed. ***/
    makeTypeaheadField(list, value) {
        let assignedToFormat = (item) => {
            return item.name + " (" + item.id + ")";
        };
        let div = document.createElement('div');
        if (this.issue.isOpen) {
            div.insertAdjacentHTML('beforeend', '<input type="text" autocomplete="off" spellcheck="false" class="typeahead"data-provide="typeahead"></input>');
            let input = div.firstChild;
            input.className = "form-control";
            let that = this;
            $(input).typeahead({
                source: list,
                autoSelect: true,
                displayText: function (item) {
                    return assignedToFormat(item);
                },
                highlighter: function(str) {
                    var query = this.query;
                    var index = str.toLowerCase().indexOf(query.toLowerCase());
                    if (index > -1) {
                        return str.substring(0, index) + '<strong>' + str.substring(index, index + query.length) + '</strong>' + str.substring(index + query.length);
                    }
                    return str;
                },
                afterSelect: function(item) {
                    that.setAssingToFromTypeahead(item.id);
                    this.$element[0].value = item.name;
                }
            });
            input.addEventListener('change', that.setAssingToFromTypeahead.bind(this, false));
            list.forEach(item => { if (item.id === value) { input.value = item.name; } });
        } else {
            let divToSend = document.createElement('div');
            let element = list.find(item => item.id === value);
            divToSend.innerText = element !== undefined ? element.name : _('No one');
            div.appendChild(divToSend);
        }
        return div.firstChild;
    }

    makeRelatedElementTypeahead() {
        let div = document.createElement('div');
        if (this.issue.isOpen) {
            div.insertAdjacentHTML('beforeend', '<input type="text" autocomplete="off" spellcheck="false" class="typeahead"data-provide="typeahead"></input>');
            let input = div.firstChild;
            input.className = "form-control";
            let that = this;
            $(input).typeahead({
                source: function(str, callback) {
                    let items = that.parent.lookUp(str.toLowerCase());
                    if (callback) {
                        callback(items);
                    }else {
                        return items;
                    }
                },
                displayText: function(item) {
                    return item.getName();
                },
                highlighter: function(str) {
                    var query = this.query;
                    var index = str.toLowerCase().indexOf(query.toLowerCase());
                    if (index > -1) {
                        return str.substring(0, index) + '<strong>' + str.substring(index, index + query.length) + '</strong>' + str.substring(index + query.length);
                    }
                    return str;
                },
                render: function (items) {
                    items = items.map(item => {
                        var text = that.parent.renderItem(item, this.highlighter(item.getName()));
                        var i = $(this.options.item).data('value', item);
                        i.find('a').html(text);
                        if (text === this.$element.val() && this.autoSelect) {
                            i.addClass('active');
                            this.$element.data('active', item);
                        }
                        return i[0];
                    });
                    this.$menu.html(items);
                    this.$menu.find('a').each(function() {
                        var $this = $(this);
                        if (!$this.attr('title')){
                            var title = $this.find('.title').text() + _(" in ") + $this.find('.model').text();
                            $this.attr('title', title);
                        }
                        $this.removeAttr("href");
                    });
                    return this;
                },
                select: function () {
                    var val = this.$menu.find('.active').data('value');
                    if (!this.autoSelect && !val) {
                        return;
                    }
                    this.$element.data("active", val);
                    if (this.autoSelect || val) {
                        if (!val && this.autoSelect) {
                            val = this.$menu.children().first().data('value');
                            if (!val) {
                                return;
                            }
                        }

                        var newVal = this.updater(val);
                        this.$element
                            .val(newVal.getName())
                            .change();
                        this.afterSelect(newVal);
                    }
                    return this.hide();
                },
                afterSelect: function(val) {
                    that.setRelatedElementFromTypeahead(val.getId());
                }
            });
            input.addEventListener('change', this.setRelatedElementFromTypeahead.bind(this, false));
            let item = this.parent.getElementById(this.issue.relatedElement);
            input.value = item ? this.itemGetName(item) : "";
        } else {
            let divToSend = document.createElement('div');
            let element = this.parent.getElementById(this.issue.relatedElement);
            let elementName = this.itemGetName(element);
            divToSend.innerText = elementName ? elementName : _("None");
            div.appendChild(divToSend);
        }
        return div.firstChild;
    }

    itemGetName(item){
        if (item){
            return item.getName() ? item.getName() : "";
        }else{
            return undefined;
        }
    }

    /*** Create error popup. ***/
    errorPopup(errorMessage, error) {
        //Create modal base
        let issueErrorPopup = document.createElement("div");
        issueErrorPopup.className = "alert alert-danger alert-dismissible";
        issueErrorPopup.id = "issueErrorPopup";
        issueErrorPopup.tabindex = "-1";
        issueErrorPopup.role = "alert";
        issueErrorPopup.innerText = errorMessage;

        this.$issueModal.find(".alert").remove();
        this.$issueModal.find(".modal-body").prepend(issueErrorPopup);
    }
}
