import { Issue } from './model/issue.js';
import i18n from './i18n.js';
import Mark from 'mark.js';
import {saveAs} from 'file-saver';

let _ = i18n('issueEditor');

/*** This object manage the view of the sidebar that display issues. ***/
export class IssuesView {
    constructor(parent, sidePanelDiv) {
        this.parent = parent;
        this.domParent = sidePanelDiv;
        this.ifCanCreateIssue = true;
        this.displayedState = true;
        this.seeDescription = false;
        this.issues = [];
        this.initSidePanelView();
    }

    setIfCanCreateIssue(ifCanCreateIssue) {
        this.ifCanCreateIssue = ifCanCreateIssue;
        let issuesButtonCreateNewIssue = document.getElementById("issuesButtonCreateNewIssue");
        if (this.ifCanCreateIssue) {
            issuesButtonCreateNewIssue.classList.remove("disabled");
        } else {
            issuesButtonCreateNewIssue.classList.add("disabled");
        }
    }

    /*** call to toggle the description. ***/
    toggleSeeDescription() {
        this.seeDescription = !this.seeDescription;
        let issuesScroll = document.getElementById("issuesScroll");
        issuesScroll.classList.toggle("hideDescription");
        this.searchInIssues(document.getElementById("issuesSearchBar"));
    }

    /*** Ask to the controller to get all the issues ***/
    async ajaxGetAllIssues() {
        try {
            let issues = [];
            let callResponse = await this.parent.getIssuesByState();
            callResponse["data"].forEach(jsonIssue => { issues.push(new Issue(jsonIssue)); });
            return issues;
        } catch (error) {
            this.logError(_("An error has occurred while trying to load the issues. Please try again later."), error);
            return null;
        }
    }

    /*** Ask to the controller to get issues by state. ***/
    async ajaxGetIssuesByState() {
        try {
            let issues = [];
            let callResponse = await this.parent.getIssuesByState(this.displayedState);
            callResponse["data"].forEach(jsonIssue => { issues.push(new Issue(jsonIssue)); });
            return issues;
        } catch (error) {
            this.logError(_("An error has occurred while trying to load the issues. Please try again later."), error);
            return null;
        }
    }

    /*** Call when button 'Open' or 'Resolved' are press. Ask to controller to gets issue by state and update the view. ***/
    async filterIssueByState(displayedState) {
        this.displayedState = displayedState;
        await this.updateView();
        this.searchInIssues(document.getElementById("issuesSearchBar"));
    }

    /*** Ask to controller to display the issueView to create issue. ***/
    showCreateNewIssuePopup() {
        this.parent.showNewIssueInPopup();
    }

    /*** Ask to controller to display the issueView to edit the issue. ***/
    showEditIssuePopup(issueId) {
        this.parent.showIssueInPopup(issueId);
    }

    /*** Filter issues by name and/or description. ***/
    searchInIssues(searchBar) {
        let searchFilter = searchBar.value;
        let acceptedIssues = this.issues.filter(issue => issue.title.toUpperCase().includes(searchFilter.toUpperCase()) || ('#' + issue.id.toString()).includes(searchFilter) || (issue.description.toUpperCase().includes(searchFilter.toUpperCase()) && this.seeDescription));
        this.updateViewFromArray(acceptedIssues);
        if (this.seeDescription) {
            this.searchScope = document.querySelectorAll(".issuesContainer > .issuesTitleContainer > .issuesTitle, .issuesContainer > .issuesDescription");
        } else {
            this.searchScope = document.querySelectorAll(".issuesContainer > .issuesTitleContainer > .issuesTitle");
        }
        new Mark(this.searchScope).mark(searchFilter, {
            "element": "span",
            "className": "select"
        });
    }

    /*** Display or hide the X in the searchbar ***/
    searchBarToggleX(searchBarNode) {
        document.getElementById("issueSearchBarRightIcon").style.visibility = searchBarNode.value.length > 0 ? "visible" : "hidden";
    }

    /*** Call when click on x in search bar. Will clear searchbar text. ***/
    clearSearchBar(issuesSearchBarNode) {
        issuesSearchBarNode.value = "";
        this.searchInIssues(issuesSearchBarNode);
        this.searchBarToggleX(issuesSearchBarNode);
    }

    async exportCSV(exportCSVButton) {
        var allIssues = await this.ajaxGetAllIssues();
        if(allIssues.length === 0) {
            return false;
        }
        var issuesToExport = [];
        var header = ["Identifier", "Title", "Description", "RelatedElement", "Reporter", "Assigned to", "Status", "Date Created", "Date Resolved"];

        allIssues.forEach(function(issue) {
            var row = [];
            let reporter = '', dateCreated = '', dateResolved = '', status = ''; 
            row.push(issue.id);
            row.push(issue.title);
            row.push(this.stripHtml(issue.description));
            row.push(issue.relatedElementName);
            if(issue.events) {
                issue.events.forEach((e) => {
                    if(e.operationType === "CREATE") {
                        dateCreated = e.date || '';
                        reporter = e.memberDisplayName || e.memberEmail || '';
                    } else if (e.operationType === "RESOLVE") {
                        dateResolved = e.date || '';
                    }
                });
            }
            row.push(reporter);
            row.push(issue.assignedTo);
            if(issue.isOpen) {
                status = 'Open';
                dateResolved = '';
            } else {
                status = 'Resolved';
            }
            row.push(status);
            row.push(dateCreated);
            row.push(dateResolved);
            issuesToExport.unshift(row);
        }, this);

        issuesToExport.unshift(header);

        var csvData = this.getCSV(issuesToExport);
        var blob = new Blob([csvData], {
            type: "application/octet-stream"
        });
        saveAs(blob, "Issues.csv");
    }

    getCSV(arr) {
        if(!arr){
            return;
        }
        return '\ufeff' + arr.map((row) => {
            return row.map((col) => {
                col = col.toString();
                if(col && /[,"\n]/.test(col)) {
                    col = '"' + col.replace(/"/g, '""') + '"';
                }
                return col;
            }).join(',');
        }).join('\n');
    }

    stripHtml(html) {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }

    /*** Create and display the header and footer of sidebar. Just creating HTML in Js and add events listeners on them. ***/
    initSidePanelView() {
        let issueMainDiv = document.createElement("div");
        issueMainDiv.id = "issuesMainDiv";

        //Header
        let issuesHeader = document.createElement("div");
        issuesHeader.id = "issuesHeader";

        //Create search bar
        let issueSearchBarDiv = document.createElement("div");
        issueSearchBarDiv.id = "issueSearchBarDiv";
        let issueSearchBarLeftIcon = document.createElement("i");
        issueSearchBarLeftIcon.id = "issueSearchBarLeftIcon";
        issueSearchBarLeftIcon.className = "fa fa-search";
        issueSearchBarDiv.appendChild(issueSearchBarLeftIcon);
        let issuesSearchBar = document.createElement("input");
        issuesSearchBar.className = "search-input form-control";
        issuesSearchBar.id = "issuesSearchBar";
        issuesSearchBar.autocomplete = "off";
        issuesSearchBar.placeholder = _("Search issue");
        issuesSearchBar.addEventListener('input', this.searchBarToggleX.bind(this, issuesSearchBar));
        issuesSearchBar.addEventListener('input', this.searchInIssues.bind(this, issuesSearchBar));
        issueSearchBarDiv.appendChild(issuesSearchBar);
        let issueSearchBarRightIcon = document.createElement("i");
        issueSearchBarRightIcon.id = "issueSearchBarRightIcon";
        issueSearchBarRightIcon.className = "search-clear fa fa-times-circle";
        issueSearchBarRightIcon.addEventListener('click', this.clearSearchBar.bind(this, issuesSearchBar));
        issueSearchBarDiv.appendChild(issueSearchBarRightIcon);
        issuesHeader.appendChild(issueSearchBarDiv);


        //Create div with buttons Open and Resolve
        let issuesTabsMenu = document.createElement("ul");
        issuesTabsMenu.id = "issuesTabsMenu";
        issuesTabsMenu.role = "tablist";
        issuesTabsMenu.className = "nav nav-tabs";
        let issuesButtonOpen = document.createElement("li");
        issuesButtonOpen.className = "issuesNavItem active";
        issuesButtonOpen.innerHTML = '<a class="issuesNavLink active" data-toggle="tab" aria-expanded="true">' + _("Open") + '</a>';
        issuesButtonOpen.addEventListener('click', this.filterIssueByState.bind(this, true));
        issuesTabsMenu.appendChild(issuesButtonOpen);
        let issuesButtonResolve = document.createElement("li");
        issuesButtonResolve.className = "issuesNavItem";
        issuesButtonResolve.innerHTML = '<a class="issuesNavLink" data-toggle="tab" aria-expanded="false">' + _("Resolved") + '</a>';
        issuesButtonResolve.addEventListener('click', this.filterIssueByState.bind(this, false));
        issuesTabsMenu.appendChild(issuesButtonResolve);
        issuesHeader.appendChild(issuesTabsMenu);

        //Create div that will contain issues list
        let issuesScroll = document.createElement("div");
        issuesScroll.id = "issuesScroll";
        issuesScroll.className = "hideDescription";

        //Create footer with buttons Create new Issue
        let issuesFooter = document.createElement("div");
        issuesFooter.id = "issuesFooter";

        let issuesButtonCreateNewIssue = document.createElement("Button");
        issuesButtonCreateNewIssue.id = "issuesButtonCreateNewIssue";
        issuesButtonCreateNewIssue.innerText = _("Report an issue");
        issuesButtonCreateNewIssue.className = "btn btn-primary";
        issuesButtonCreateNewIssue.addEventListener('click', this.showCreateNewIssuePopup.bind(this));
        issuesFooter.appendChild(issuesButtonCreateNewIssue);
        if (!this.parent.isInModeler()){
            issuesFooter.style.display = "none";
        }

        //Append element to the domParent
        issueMainDiv.appendChild(issuesHeader);
        issueMainDiv.appendChild(issuesScroll);
        issueMainDiv.appendChild(issuesFooter);
        this.domParent.appendChild(issueMainDiv);
    }

    changeViewMode(){
        if (!this.parent.isInModeler()){
            document.getElementById("issuesFooter").style.display = "none";
        }else{
            document.getElementById("issuesFooter").style.display = "";
        }
    }

    /*** Remove all children from a given html node. ***/
    removeAllChildFromNode(node) {
        while (node.firstChild) {
            node.removeChild(node.firstChild);
        }
    }

    /*** Update the view base on array pass. ***/
    updateViewFromArray(issues) {
        if (issues === null) {
            return;
        }
        let issuesScrollNode = document.getElementById("issuesScroll");

        // The view is not created yet
        if (!issuesScrollNode) {
            return;
        }
        this.removeAllChildFromNode(issuesScrollNode);
        for (const index in issues) {
            let domIssue = this.getElementByIdInsideContainer("issuesScroll", this.issues[index].id);
            if (domIssue === null) {
                this.createIssueInSidePanel(issues[index]);
            }
        }
        if (issues.length === 0){
            issuesScrollNode.innerHTML = '<div id="issuesCenter"><span id="issuesNoIssue">' + _("No issues") + '</span></div>';
        }
    }

    triggerCallback(){
        this.issues.forEach((issue)=>{
            if (issue.relatedElement !== "") {
                this.parent.callback('addRelatedElement', issue.id, issue.relatedElement);
            }
        });
    }

    /*** Update the view based on the array pass parameter. ***/
    async updateView() {
        document.getElementById("issuesButtonCreateNewIssue").disabled = false;
        this.issues = await this.ajaxGetIssuesByState();
        this.updateViewFromArray(this.issues);
    }

    /*** Create an issue in HTML to display in sidebar. Just creating HTML in Js and add an event listener on it. ***/
    createIssueInSidePanel(issue) {
        //Div container of the issue
        let issuesContainer = document.createElement("div");
        issuesContainer.className = "issuesContainer";
        issuesContainer.id = issue.id;
        issuesContainer.addEventListener('click', this.showEditIssuePopup.bind(this, issue.id));

        //Div container of the issue title, issue Id and issue comments counter
        let issuesTitleContainer = document.createElement("div");
        issuesTitleContainer.className = "issuesTitleContainer";

        //Div that contain the title and the id of the issue
        let issuesTitle = document.createElement("div");
        issuesTitle.className = "issuesTitle";
        issuesTitle.title = issue.title;
        issuesTitle.setAttribute('data-toggle', 'tooltip');
        issuesTitle.innerText = issue.title;
        let issuesId =  document.createElement("i");
        issuesId.className = "issuesId";
        issuesId.innerText = ' #' + issue.id;
        issuesTitle.appendChild(issuesId);
        issuesTitleContainer.appendChild(issuesTitle);

        //Div that contain the issue comments counter
        let issuesCommentsCounter = document.createElement("div");
        issuesCommentsCounter.className = "issuesCommentsCounter";
        issuesCommentsCounter.innerHTML = '<i class="fa fa-comments" aria-hidden="true">' + issue.commentsCount() + '</i>';
        issuesTitleContainer.appendChild(issuesCommentsCounter);

        //Div that contain the issue description
        let issuesDescription = document.createElement("div");
        issuesDescription.className = "issuesDescription";
        issuesDescription.title = $(issue.description).text().replace(/\n/g, ' ');
        issuesDescription.setAttribute('data-toggle', 'tooltip');
        issuesDescription.innerText = $(issue.description).text().replace(/\n/g, ' ');
        //Append element to the di v container of the issue
        issuesContainer.appendChild(issuesTitleContainer);
        issuesContainer.appendChild(issuesDescription);

        //Append element to the issuesContainer
        document.getElementById("issuesScroll").appendChild(issuesContainer);
    }

    /*** Create error popup. ***/
    logError(errorMessage, error) {
        let issuesScrollNode = document.getElementById("issuesScroll");
        issuesScrollNode.innerHTML = "<div class='error'>" + errorMessage + "</div>";
        document.getElementById("issuesButtonCreateNewIssue").disabled = true;
    }


    /*** Utility function to search for dom by id elements in the children of a given node. ***/
    getElementByIdInsideContainer(containerID, childID) {
        let acceptedNode = null;
        let elements = document.getElementById(containerID).childNodes;
        for (const index in elements) {
            // eslint-disable-next-line eqeqeq
            if (elements[index].id == childID) {
                acceptedNode = elements[index];
                break;
            }
        }
        return acceptedNode;
    }

}
