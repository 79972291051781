import { IssuesView } from "./issuesView.js";
import { IssueView } from "./issueView.js";
import { Issue } from "./model/issue.js";

/*** This is the controller to communicate between each view and make all ajax calls. ***/
export class IssueController {
    constructor({ serverUrl, elementAPI = null, fileId = null, repositoryId, sidePanelDiv, fnRelatedElementAdded = null, fnRelatedElementRemoved = null, tinymceSkinUrl = null }) {
        this.fileId = fileId;
        this.repositoryId = repositoryId;
        this.elementAPI = elementAPI;
        this.endPoint = serverUrl;
        this.listener = {
            addRelatedElement: [],
            removeRelatedElement: []
        };
        this.register('addRelatedElement', fnRelatedElementAdded);
        this.register('removeRelatedElement', fnRelatedElementRemoved);
        this.issuesView = new IssuesView(this, sidePanelDiv);
        this.issueView = new IssueView(this, sidePanelDiv, tinymceSkinUrl);
    }

    /*** This will set the fileId and repositoryId and update the view. ***/
    setScopeInformation(fileId, repositoryId){
        this.fileId = fileId;
        this.repositoryId = repositoryId;
        this.issuesView.changeViewMode();
        if (this.issuesView){
            this.issuesView.updateView();
        }
    }

    /*** This will get all user information and curent user information to be able to update IssuesView. ***/
    async init() {
        let usersInformations = await Promise.all([this.ajaxgetCurrentUserInformations(), this.ajaxGetUsersInformations()]);
        this.currentUserInformations = usersInformations[0]["data"];
        this.usersInformations = usersInformations[1]["data"];
        let mimetypes = await this.getMimetypes();
        this.mimetypes = mimetypes.data;
        await this.issuesView.updateView();
        this.issuesView.triggerCallback();
    }

    /*** This will tell if the user have provided a ElementAPI. ***/
    isRelatedElementAPI(){
        return this.elementAPI ? true : false;
    }

    /*** This will tell if the current repository is a personal repository. ***/
    isPersonalRepository(){
        return this.repositoryId === "|personal|";
    }

    /*** This will tell if the plugin is in a model or a repository. ***/
    isInModeler(){
        return this.fileId ? true : false;
    }

    /*** This will return the current user information ***/
    getCurrentUserInformations(){
        return this.currentUserInformations;
    }

    /*** This will return all users general information (name, email) of the server ***/
    getUsersInformations(){
        return this.usersInformations;
    }

    /*** This set the state of the create new issue button in IssuesView. ***/
    setIfCanCreateIssue(ifCanCreateIssue) {
        this.issuesView.setIfCanCreateIssue(ifCanCreateIssue);
    }

    /*** Make ajax call to get issues filter by state. ***/
    async getIssuesByState(state) {
        if (this.fileId != null) {
            return this.ajaxGetIssueByFile(state);
        } else {
            return this.ajaxGetIssueByRepository(state);
        }
    }

    /*** Ask to the issuesView to update the view. ***/
    updateIssuesView() {
        this.issuesView.updateView();
    }

    lookUp(str) {
        return this.elementAPI.lookUp(str);
    }

    renderItem(item, title){
        return this.elementAPI.renderItem(item, title);
    }

    /*** Ask to the elementAPI to get the selected element in the modeler. ***/
    getSelectedElement() {
        return this.elementAPI.getSelectedElement();
    }

    /*** Ask to the elementAPI to get the selected element by id in the modeler. ***/
    getElementById(elementId) {
        return this.elementAPI.getElementById(elementId);
    }

    /*** Toggle the description in IssueView. ***/
    toggleSeeDescription() {
        this.issuesView.toggleSeeDescription();
    }

    exportCSV() {
        this.issuesView.exportCSV();
    }

    /*** register, unregister and callback are functions to register, unregister and call a listener. ***/
    register(name, callback) {
        if (typeof callback === "function") {
            this.listener[name].push(callback);
        }
    }

    unregister(name, callback) {
        this.listener[name].filter(fn => fn !== callback);
    }

    callback(name) {
        for (let i = this.listener[name].length - 1; i >= 0; i--) { // iterate backwards because the callback can remove listeners
            if (typeof this.listener[name][i] === "function") {
                this.listener[name][i].apply(this, Array.prototype.slice.call(arguments, 1)); //remove the first argument and call the callback
            } else {
                this.listener[name].splice(i, 1);   // clean the array
            }
        }
    }

    /*** Ask to issueView to display a issue. ***/
    async showIssueInPopup(issueId) {
        this.issueView.popupFromExistingIssue(issueId);
    }

    /*** Ask to issueView to display editing view to create a issue. ***/
    async showNewIssueInPopup() {
        this.issueView.createPopupView(new Issue({}));
    }

    /*** Call ajax to get issues by states of a repository. ***/
    async ajaxGetIssueByRepository(state) {
        var params = { mode: "json", repository: this.repositoryId};
        if(typeof state !== 'undefined') {
            params.open = state;
        }
        return this.issueAjaxGetCall(new URLSearchParams(params));
    }

    /*** Call ajax to get issues by states for a file. ***/
    async ajaxGetIssueByFile(state) {
        var params = { mode: "json", repository: this.repositoryId, fileId: this.fileId};
        if(typeof state !== 'undefined') {
            params.open = state;
        }
        return this.issueAjaxGetCall(new URLSearchParams(params));
    }

    /*** Call ajax to get an issue by id. ***/
    async ajaxGetIssueById(issueId) {
        return this.issueAjaxGetCall(new URLSearchParams({ mode: "json", repository: this.repositoryId, issueId: issueId }));
    }

    /*** Call ajax to create an issue. ***/
    async ajaxCreateIssue(issue, fileId = null) {
        return this.issueAjaxCall(new URLSearchParams({ mode: "json", repository: this.repositoryId, fileId: fileId === null ? this.fileId : fileId }), 'POST', JSON.stringify(issue));
    }

    /*** Call ajax to update an issue. ***/
    async ajaxUpdateIssue(issue) {
        let jsonIssue = JSON.stringify(issue, function (key, val) { if (key !== "events") { return val; } });
        return this.issueAjaxCall(new URLSearchParams({ mode: "json", repository: this.repositoryId }), 'PUT', jsonIssue);
    }

    /*** Call ajax to delete an issue. ***/
    async ajaxDeleteIssue(issueId) {
        return this.issueAjaxCall(new URLSearchParams({ mode: "json", repository: this.repositoryId, issueId: issueId }), 'DELETE');
    }

    /*** Call ajax to get all comments of an issue. ***/
    async ajaxGetCommentsOfIssue(issueId) {
        return this.issueCommentAjaxGetCall(new URLSearchParams({ mode: "json", repository: this.repositoryId, issueId: issueId }));
    }

    /*** Call ajax to create a comment. ***/
    async ajaxCreateComment(issueId, comment) {
        return this.issueCommentAjaxCall(new URLSearchParams({ mode: "json", repository: this.repositoryId, issueId: issueId }), 'POST', JSON.stringify(comment));
    }

    /*** Call ajax to update a comment. ***/
    async ajaxUpdateComment(issueId, comment) {
        return this.issueCommentAjaxCall(new URLSearchParams({ mode: "json", repository: this.repositoryId, issueId: issueId }), 'PUT', JSON.stringify(comment));
    }

    /*** Call ajax to delete a comment. ***/
    async ajaxDeleteComment(issueId, commentId) {
        return this.issueCommentAjaxCall(new URLSearchParams({ mode: "json", repository: this.repositoryId, issueId: issueId, commentId: commentId }), 'DELETE');
    }

    /*** Call ajax to get all members from share place. ***/
    async ajaxGetMembersFromSharePlace() {
        return this.ajaxGetCall('/publicapi/repositorymember', new URLSearchParams({ mode: "json", id: this.repositoryId, type: "User", flat: true}));
    }

    /*** Call ajax to get the current user information (Name, Email, Picture). ***/
    async ajaxgetCurrentUserInformations() {
        return this.ajaxGetCall('/publicapi/login', new URLSearchParams({ mode: "json" }));
    }

    /*** Call ajax to get all users information (Name, Email). ***/
    async ajaxGetUsersInformations() {
        return this.ajaxGetCall('/publicapi/user', new URLSearchParams({ mode: "json" }));
    }

    /*** Call ajax to get all users information (Name, Email). ***/
    async ajaxGetfileInformations(fileId) {
        return this.ajaxGetCall('/publicapi/repositorycontent', new URLSearchParams({ mode: "json", repository: this.repositoryId, id: fileId }));
    }

    /*** Call ajax to for issue related call. ***/
    async issueAjaxCall(params, httpRequestMethod, bodyParameter = ""){
        return this.ajaxCall('/publicapi/issue', params, httpRequestMethod, bodyParameter);
    }

    /*** Call ajax GET to for issue related call. ***/
    async issueAjaxGetCall(params){
        return this.ajaxGetCall('/publicapi/issue', params);
    }

    /*** Call ajax to for issueComment related call. ***/
    async issueCommentAjaxCall(params, httpRequestMethod, bodyParameter){
        return this.ajaxCall('/publicapi/issuecomment', params, httpRequestMethod, bodyParameter);
    }

    /*** Call ajax GET to for issueComment related call. ***/
    async issueCommentAjaxGetCall(params){
        return this.ajaxGetCall('/publicapi/issuecomment', params);
    }

    /*** Generic function to make ajax call. ***/
    async ajaxCall(url, params, httpRequestMethod, bodyParameter){
        let request = {
            method: httpRequestMethod,
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: bodyParameter
        };
        let response = await fetch(this.endPoint + url + "?" + params.toString(), request);
        if (response.ok) {
            return await response.json();
        } else {
            // Try to parse it anyway to get the error from the back end
            response = await response.json();
            throw new PublicAPIError(response.error);
        }
    }

    /*** Generic function to make ajax GET call. ***/
    async ajaxGetCall(url, params){
        let request = {
            method: 'GET',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        };
        let response = await fetch(this.endPoint + url + "?" + params.toString(), request);
        if (response.ok) {
            return await response.json();
        } else {
            // Try to parse it anyway to get the error from the back end
            response = await response.json();
            throw new PublicAPIError(response.error);
        }
    }

    async getMimetypes(){
        let request = {
            method: 'GET',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        };
        let response = await fetch(this.endPoint + '/publicapi/mimetype?mode=json', request);
        if (response.ok) {
            return await response.json();
        } else {
            // Try to parse it anyway to get the error from the back end
            response = await response.json();
            throw new PublicAPIError(response.error);
        }
    }
}

class PublicAPIError extends Error {
    constructor(errorResponse) {
        if (Array.isArray(errorResponse)) {
            errorResponse = errorResponse[0];
        }
        super(errorResponse.systemMessage);
        this.name = errorResponse.code;
    }
}
