(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("i18next"), require("tinymce"), require("jquery"));
	else if(typeof define === 'function' && define.amd)
		define("@trisotech/issue-editor", ["i18next", "tinymce", "jquery"], factory);
	else if(typeof exports === 'object')
		exports["@trisotech/issue-editor"] = factory(require("i18next"), require("tinymce"), require("jquery"));
	else
		root["@trisotech/issue-editor"] = factory(root["i18next"], root["tinymce"], root["jQuery"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__661__, __WEBPACK_EXTERNAL_MODULE__211__, __WEBPACK_EXTERNAL_MODULE__140__) {
return 