import i18n from '../i18n.js';

let _ = i18n('issueEditor');
/*** This is the object that store all information about a issue. ***/
export class Issue {
    constructor({ id = -1, isOpen = true, title = "", description = "", relatedElement = "", relatedElementName = "", events = [], assignedTo = "", fileId = "" }) {
        this.id = id;
        this.isOpen = isOpen;
        this.title = title;
        this.description = description;
        this.relatedElement = relatedElement;
        this.relatedElementName = relatedElementName;
        this.assignedTo = assignedTo;
        this.fileId = fileId;
        this.events = events;
    }

    getIssueCreatorEmail() {
        return this.events[0].authorEmail;
    }

    /*** This will generate the message to display for system comments. This will translate it self with i18next. ***/
    eventsToComments(usersInformation) {
        this.events = this.events.map(event => this.eventToComment(event, usersInformation));
    }

    eventToComment(event, usersInformation){
        let comment = {};
        if (event.operationType !== "COMMENT") {
            switch (event.operationType) {
                case "CREATE":
                    comment.message = _("Has reported the issue.");
                    break;
                case "UPDATE":
                    if (event.updatedField === "assignedTo"){
                        let assignedToOld = usersInformation.find(user => user.email === event.oldValue);
                        assignedToOld = assignedToOld ? assignedToOld: event.oldValue;
                        let assignedToNew = usersInformation.find(user => user.email === event.newValue);
                        assignedToNew = assignedToNew ? assignedToNew: event.newValue;
                        if (assignedToNew === "" && assignedToOld !== null){
                            comment.message = _("Has unassigned {{oldValue}}.", {oldValue: assignedToOld.name});
                        }else if (assignedToOld === "" && assignedToNew !== null){
                            comment.message = _("Has assigned {{newValue}}.", {newValue: assignedToNew.name});
                        } else{
                            comment.message = _("Has unassigned {{oldValue}} and assigned {{newValue}}.", {oldValue: assignedToOld.name, newValue: assignedToNew.name});
                        }
                    } else {
                        event.oldValue = $('<p>' + event.oldValue + '</p>').text();
                        event.newValue = $('<p>' + event.newValue + '</p>').text();
                        if (event.oldValue === ""){
                            comment.message = _("Has updated the issue {{field}} to \"{{newValue}}\".", {field: _(event.updatedField), oldValue: event.oldValue, newValue: event.newValue});
                        } else {
                            comment.message = _("Has updated the issue {{field}} from \"{{oldValue}}\" to \"{{newValue}}\".", {field: _(event.updatedField), oldValue: event.oldValue, newValue: event.newValue});
                        }
                    }
                    break;
                case "RESOLVE":
                    comment.message = _("Has resolved the issue.");
                    break;
                case "REOPEN":
                    comment.message = _("Has reopened the issue.");
                    break;
                default:
                    comment.message = "";
            }
            comment.type = "SystemComment";
            comment.replyTo = null;
        }else{
            comment.message = event.message;
            comment.replyTo = event.replyTo ? event.replyTo: null;
            comment.type = "Comment";
        }
        comment.message = comment.message.replace("&#39;", "'");
        comment.guid = event.guid;
        comment.authorEmail = event.memberEmail;
        comment.authorDisplayName = event.memberDisplayName;
        comment.authorImage = event.memberImage;
        comment.pubDate = this.getRFC822Date(new Date(event.date));
        comment.bpmElement= "issueView";
        return comment;
    }

    /*** This count the number of comments that are not system comments. ***/
    commentsCount() {
        let count = 0;
        this.events.forEach(comment => {
            if (comment.operationType === "COMMENT") {
                count++;
            }
        });
        return count;
    }

    getRFC822Date(oDate) {
        const getTZOString = function(timezoneOffset) {
            var hours = Math.floor(timezoneOffset / 60);
            var modMin = Math.abs(timezoneOffset % 60);
            var s = "";
            s += (hours > 0) ? "-" : "+";
            var absHours = Math.abs(hours);
            s += (absHours < 10) ? "0" + absHours : absHours;
            s += ((modMin === 0) ? "00" : modMin);
            return (s);
        };

        const padWithZero = function(val) {
            if (parseInt(val) < 10) {
                return "0" + val;
            }
            return val;
        };
        var aMonths = new Array("Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec");

        var aDays = new Array("Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat");
        var dtm = "";

        dtm = aDays[oDate.getDay()] + ", ";
        dtm += padWithZero(oDate.getDate()) + " ";
        dtm += aMonths[oDate.getMonth()] + " ";
        dtm += oDate.getFullYear() + " ";
        dtm += padWithZero(oDate.getHours()) + ":";
        dtm += padWithZero(oDate.getMinutes()) + ":";
        dtm += padWithZero(oDate.getSeconds()) + " ";
        dtm += getTZOString(oDate.getTimezoneOffset());
        return dtm;
    }
}
